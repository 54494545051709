.mycard {
    
    max-width: 19.2rem;
    min-height: 31.5rem;
    max-height: 31.5rem;
   
   
    margin: 10px;
    margin-left: 0px;
    background-size: cover;
    
  
}

.bgg {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.74), rgba(0, 0, 0, 0.44) 77.23%, rgba(0, 0, 0, 0));
    /*background: linear-gradient(0deg, rgba(0, 0, 0, 0.74), rgba(0, 0, 0, 0.44) 77.23%, rgba(0, 0, 0, 0));*/
}

.bgc{
    filter: blur(1px);
}

.mt-2-cust{
    margin-top: 75%;
}