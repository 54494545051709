.transition-div {
  opacity: 0;
  height: 0;
  transition: opacity 2s ease, height 2s ease;
  overflow: hidden;
}

.show-div {
  opacity: 1;
  height: auto;
}

.blog-container{
  scroll-behavior: smooth;
}
.blur-text {
  filter: blur(5px); /* You can adjust the blur strength */
}
.border-cust{
    border: solid green 2px;
}

.ml-cust-10 {
    margin-left: 8.7%;
}

.ml-cust-67{
  margin-left:6.7%;
}

.ml-cust-102{
  margin-left:6.7%;
}

.mr-cust-10 {
    margin-right: 6.7%;
}

.px-cust-10{
  padding-left:6.7%;
  padding-right:6.7%;
}

.ml-cust{
    margin-left: 7.6%;
}

.textclr-cust{
  color: rgba(255, 255, 255, 0.6);
}

.mr-cust-slider{
    margin-right: 10%;
}

.ml-cust-services{
  margin-left:4%;
}

.bordcust{
  border: 1px solid rgba(52, 97, 255, 0.12);
}

.w-cust-slider{
    width:87%;
}
.bgnetflix{
  background-color: rgba(0,0,0,.75);
}
  
.mr-cust-8{
  margin-right: 10%;
}
  
.bgcust{
  background-color: #f0f0f0 ;
}

.navbarbg{
  background-color:rgb(36,38,39);
}
  
  .max-height-cust{
    max-height: 870px;
    min-height: 500px;
  }

  .min-h-cust{
    min-height: 200px;
    max-height: 300px;
  }

  .bg-url{
    background-image: url('../public/sh.png');
    height: 100%;
    width: 100%;
   background-size: contain;
   background-position: 'center';
   background-repeat: no-repeat;

  }

  .w-half-cust{
    width: 40%;
  }


  .testimonial-container {
    padding: 0 10px;
    
    overflow-x: hidden;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
}


.gradient1 {
  width: 100px; /* Adjust the width and height as needed */
  height: 100px;
  background: linear-gradient(to right, #3461FF, #577CFF);
}

.gradient2 {
  width: 100px; /* Adjust the width and height as needed */
  height: 100px;
  background: linear-gradient(to right, #577CFF, #3461FF);
}

.trailbgimg{
  background-image: url('../public/freetrial.svg');
  background-size: cover;
  border-radius: 24px;
}

/*
.medical-img{
  background-image: url('../public/rectangle-197@2x.png');
  background-size: cover;
  height: 100%;
  width: 30vw;
  background-position: center;
}

*/
.popup-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%; /* Adjust the width as needed */
  max-width: 400px; /* Set a maximum width if desired */
  height: auto;
  background: green;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.2rem;
  padding: 20px; /* Add padding as needed */
  backdrop-filter: blur(8px)
}

.popup-content {
  text-align: center;
  margin-bottom: 15px; /* Add margin between text and button */
}

.popup-close-btn {
  background: white;
  color: #4caf50;
  border: none;
  padding: 8px 16px;
  font-size: 1rem;
  cursor: pointer;
}



.backdrop-filter-cust{
  
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);

}

.bgwhite{
  background-color: #FFFFFF;
}

.bgcustnav{
  background: hsl(226,36%,21%);
}

.bgclrtrail{
  
  background-color: rgba(10, 24, 71, 0.31);

}

.bgcust2{
  background: rgb(165,169,184);
  
}

.text-custom{

  color: rgba(255, 255, 255, 0.60);
}

.df{
  background: rgba(52.06, 96.71, 255, 0.02);
}

.pl-cust-7{
  padding-left: 7%;
}

.mr-cust-5 {
  margin-right: 5%;
}
.pr-cust-7{
  padding-right: 4%;
}

.px-cust {
  padding-left: 4%;
}

.ml-cust-14{
  margin-left: 14%;
}

.header{
  background-image: url('../public/image-2@2x.png');
  background-size: cover;
  
}

.bgghostwhitecust{
  background-color: rgb(247 251 255 / var(--tw-bg-opacity));

}

.border-round-custom{
  border: 1px solid #D4D7E3;
}

.border-custom-2{
  border:1px solid rgba(0, 0, 0, 0.1);
  
}