@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}




